
.canvas {
  width:100vw; height:100vh; 
  position: absolute;
  z-index: -1;
}

.floating-boxes {
  position: absolute !important;
  top: 30px;
  left: 30px;
  width: 250px;
  background-color: argb(0,0,0,0);
}


.tektite-logo {
  width: 100px;
}

.tektite-logo-sm {
  height: 40px;
}

.mobile-header {
  padding: 10px;
  height: 50px;
}